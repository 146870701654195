import KnImagesGalleryItem from '../KnImagesGalleryItem/KnImagesGalleryItem.vue';
export default {
  components: { KnImagesGalleryItem },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    cols: {
      type: [String, Number],
      default: '2'
    },
    value: {
      type: null
    },
    currentPage:{
      default: 1
    },
    totalPagesCount:{
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      imagesSelected: []
    }
  },
  computed: {
    colsImage() {
      return this.$vuetify.breakpoint.mobile ? '6' : this.cols
    },
    totalPages() {
      return Math.ceil(this.totalPagesCount / 5);
    },
  },
  watch: {
    // Handles internal model changes.
    imagesSelected (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.imagesSelected = newVal;
    }
  },
  created () {
    if (this.value) {
      this.imagesSelected = this.value;
    }
  },
  methods:{
    emitChangePage(value) {
      this.$emit('onChangeScroll',value);
    },
    toggleImageSelection(imageId) {
    if (!this.imagesSelected.includes(imageId)) 
      this.imagesSelected.push(imageId);
    else {
      const index = this.imagesSelected.indexOf(imageId);
      if (index !== -1) 
        this.imagesSelected.splice(index, 1);
    }
    // console.log(this.imagesSelected);
  }
  }
}