<template>
  <v-row :align="columnAlign" :justify="columnJustify">
    <v-col
      v-if="showLabel"
      class="py-0"
      order="1"
      cols="12"
      md="4"
      lg="4"
      xl="4"
    >
      <v-subheader>
        {{ label }}<span v-if="isMandatory" class="error--text">*</span>
      </v-subheader>
    </v-col>
    <v-col class="py-0" :cols="colsBtn" order="2">
      <v-btn
        class="mt-1"
        outlined
        tile
        block
        :disabled="disabled"
        @click="openFileInput"
      >
        <span class="text-caption text-none">Seleccionar archivo</span>
      </v-btn>
      <input
        :id="idInput"
        class="hidden-input-file"
        type="file"
        value="upload"
        :multiple="multiple"
        :disabled="disabled"
        @change="getFile()"
      />
    </v-col>
    <v-col
      class="py-0"
      :cols="colsFile"
      order="4"
      order-md="3"
      order-lg="3"
      order-xl="3"
    >
      <p class="text-caption purple--text text-truncate pt-3">
        {{ showFileName }}
      </p>
    </v-col>
    <v-col
      v-if="fileName"
      class="py-0 text-right"
      :cols="colsIcon"
      order="3"
      order-md="4"
      order-lg="4"
      order-xl="4"
    >
      <v-btn
        icon
        color="primary"
        title="Limpiar"
        :disabled="disabled"
        @click="clearFile"
      >
        <v-icon color="primary">mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    idInput: {
      type: String,
      default: 'fileInput',
    },
    label: {
      type: String,
      default: '',
    },
    docObj: {
      type: Object,
      default: null,
    },
    colBtn: {
      type: String,
      default: '3',
    },
    docType: {
      type: [String, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    isMandatory: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      fileName: null,
    };
  },
  computed: {
    showFileName() {
      return this.fileName !== null
        ? this.multiple
          ? this.fileName.join(', ')
          : this.fileName
        : 'No se ha seleccionado ningún archivo';
    },
    colsFile() {
      return this.$vuetify.breakpoint.mobile
        ? '12'
        : this.fileName !== null
        ? 7 - this.colBtn
        : 8 - this.colBtn;
    },
    columnAlign() {
      return this.column ? 'center' : 'start';
    },
    columnJustify() {
      return this.column ? 'center' : 'start';
    },
    colsIcon() {
      return this.$vuetify.breakpoint.mobile ? '2' : '1';
    },
    colsBtn() {
      return this.$vuetify.breakpoint.mobile
        ? this.fileName !== null
          ? '10'
          : '12'
        : this.colBtn;
    },
  },
  created() {
    if (this.docObj !== null) {
      this.fileName = this.multiple
        ? this.docObj.map((file) => file.fileName)
        : this.docObj.fileName;
    }
  },
  methods: {
    openFileInput() {
      document.getElementById(this.idInput).click();
    },
    getFile() {
      // console.log(this.idInput)
      const input = document.getElementById(this.idInput);
      this.file = this.multiple ? [...input.files] : input.files[0];
      this.fileName = this.multiple
        ? this.file.map((file) => file.name)
        : input.files[0].name;
      // console.log('File? ', this.file);
      // console.log('Nombre de archivo? ', this.fileName);
      this.emitFile();
    },
    clearFile() {
      this.emitClearFile();
      this.file = null;
      this.fileName = null;
    },
    emitFile() {
      this.$emit('saveFile', {
        file: this.file,
        fileName: this.fileName,
        type: this.docType,
      });
    },
    emitClearFile() {
      this.$emit('clearFile', { file: this.file, type: this.docType });
    },
  },
};
</script>

<style>
.hidden-input-file {
  display: none;
}
</style>
