export default {
  props: {
    image: {
      type: Object,
      default: () => {}
    },
    value: {
      type: null
    },
    imagesSelected: {
      type: Array
    },
  },
  data() {
    return {
      innerValue: false,
      isSelected: this.imagesSelected
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  computed:{

  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    toggleSelection() {
      this.$emit('toggle', this.image.id, this.isSelected);
    }

  }
}