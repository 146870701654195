export default {
  props: {
    doc: {
      type: Object,
      default: () => {}
    },
    value: {
      type: null
    },
    documentsSelected: {
      type: Array
    },
  },
  data() {
    return {
      innerValue: false,
      isSelected: this.documentsSelected
    }
  },
  computed: {
    truncateText() {
      const docName = this.doc.nombre_documento
      return docName.length > 29 ? `${docName.substring(0, 29)}` : docName
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  methods: {
    toggleSelection() {
      this.$emit('toggle', this.doc.id, this.isSelected);
    }

  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}