import KnDocsGalleryItem from '../KnDocsGalleryItem/KnDocsGalleryItem.vue';

export default {
  components: { KnDocsGalleryItem },
  props: {
    docs: {
      type: Array,
      default: () => []
    },
    cols: {
      type: [String, Number],
      default: '2'
    },
    value: {
      type: null
    },
    currentPage:{
      default: 1
    },
    totalPagesCount:{
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      docsSelected: []
    }
  },
  watch: {
    // Handles internal model changes.
    docsSelected(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.docsSelected = newVal;
    }
  },
  created() {
    if (this.value) {
      this.docsSelected = this.value;
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalPagesCount / 5);
    },
  },

  methods:{
    emitChangePage(value) {
      this.$emit('onChangeScroll',value);
    },
    toggleDocumentSelection(imageId) {
      if (!this.docsSelected.includes(imageId)) 
        this.docsSelected.push(imageId);
      else {
        const index = this.docsSelected.indexOf(imageId);
        if (index !== -1) 
          this.docsSelected.splice(index, 1);
      }
      // console.log(this.docsSelected);
    }
  }
}